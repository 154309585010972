import iconLogo from 'assets/images/logo-2.png'
import iconLogo2 from 'assets/images/logo-2.png'
import { breakpointsMedias } from 'configs/breakpoints'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useWidthScreen } from 'helpers/useScreen'

interface ILogo {
    noMobile?: boolean
}

const Logo = ({ noMobile }: ILogo) => {
    const { width } = useWidthScreen()

    return (
        <Wrap id="logo">
            <Link to="" className="logo">
                <img src={width >= 1200 ? iconLogo : iconLogo2} alt="" />
            </Link>
        </Wrap>
    )
}

export default Logo

const Wrap = styled.div`
    height: fit-content;
    cursor: pointer;
    margin-right: auto;
    display: flex;
    .logo {
        display: flex;
        height: fit-content;
        > img {
            width: 88px;
            height: auto;
        }
    }
    ${breakpointsMedias.min768} {

    }
    ${breakpointsMedias.min1200} {
        .logo {
            > img {
                width: 118px;
                height: auto;
            }
        }
    }
    ${breakpointsMedias.min1600} {
        /* .logo {
            > img {
                width: 64px;
                height: auto;
            }
        } */
    }
`