import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from './LoadingSpinner';
import configColor from 'configs/configColor';
import { breakpointsMedias } from 'configs/breakpoints';
import bgBt from "assets/images/bg-bt.png"

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    isLoading?: boolean,
    sizeBt?: "small" | "normal",
    className?: string,
    disabled?: boolean,
    hasShadow?: boolean
}

const Button = ({ text, isLoading = false, sizeBt = "normal", className, disabled = false, hasShadow, ...props }: IB) => {
    const { t } = useTranslation();
    return (
        <Wrap className={`${isLoading ? "bt-loading" : ""} bt-${sizeBt} ${className} ${hasShadow ? "bt-shadow" : ""}`} disabled={disabled || isLoading} {...props}>
            <div>
                <span className={`color-green size-0`}>{!!isLoading ? <LoadingSpinner />
                    : <>
                        {t(text)}
                    </>}</span>
            </div>

        </Wrap>
    )
}
export default Button

const Wrap = styled.button`
    /* max-width: 100%; */
    min-width: 146px;
    height: 36px;
    &.bt-small {
        height: 28px;
        border-radius: 6px;
        padding: 0 7px;
        min-width: 68px;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        background: #180F26;
        border: 1px solid #40C72A;
        border-radius: 6px;
        > span {
            transition: 0.3s;
        }
        &:hover {
            > span {
                text-shadow: 0px 0px 32px rgb(5, 233, 35), 0px 8px 24px #02880e;
            }
        }
    }
  
    /* &:active,
    &:focus, */
   
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.bt-loading {
        opacity: 0.4;
        cursor: not-allowed;
        :hover {
            background: ${configColor.colorBG};
            border: 1px solid ${configColor.colorPrimary};
        }
    }
    &.bt-shadow::before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background-color: ${configColor.colorGreen};
        position: absolute;
        top: 8px;
        left: 8px;
    }
    @keyframes rotate-center-2 {
        0% {
            transform: rotate(0);
        }
        100% {
           transform: rotate(360deg);
        }
    }
    ${breakpointsMedias.min1200} {
        min-width: 146px;
        height: 50px;
        &.bt-small {
            height: 32px;
        }
    }
`