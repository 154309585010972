import { useWidthScreen } from 'helpers/useScreen'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { breakpointsMedias } from '../../configs/breakpoints'
import Logo from './Logo'
import Menu from './Menu'
import MenuButton from './MenuButton'
import bgHeader from 'assets/images/header-bg.png'
import bgHeader2 from 'assets/images/bg-header-2.png'
import Button from 'components/core/Button'

const Header = () => {
    const [show, setShow] = useState<boolean>(false);
    const { width } = useWidthScreen();
    const [bgColor, setBgcolor] = useState(false)

    useEffect(() => {
        window.onscroll = function () {
            // We add pageYOffset for compatibility with IE.
            if (window.scrollY >= 10 || window.pageYOffset >= 10) {
                document.getElementById("header")?.classList.add("has-color");
            } else {
                document.getElementById("header")?.classList.remove("has-color");
            }
        };
    }, [])

    return (
        <Wrap className={`${bgColor ? "has-color" : ""}`} id='header'>
            <div className="wrap-header">
                <Logo />
                <div className="menu ">
                    {width >= 1200 ? <Menu onClose={() => { }} />
                        : <MenuButton onClick={() => { setShow(!show) }} show={show} />}
                </div>
                {width >= 1200 && <Button text='Buy Now' hasShadow />}
            </div>
            <div className={`menu-mobile ${show && "menu-mobile-active"}`} onClick={() => { setShow(false) }}>
                <Menu onClose={() => { setShow(false) }} />
            </div>
        </Wrap>
    )
}

export default Header

const Wrap = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 88px;
    z-index: 2;
    &.has-color {
        background: #251C40;
    }
   
    .wrap-header {
        display: flex;
        align-items: center;
        padding: 0 16px 0 0;
        width: 100%;
        height: 100%;
        z-index: 1;
       
    }
    .menu-mobile {
        position: fixed;
        width: 100%;
        display: flex;
        height: 100%;
        transition: 0.3s;
        top: 88px;
        right: -100%;
        z-index: 0;
        background-color: #000000b4;
    }
    .menu-mobile-active {
        right: 0;
    }
    ${breakpointsMedias.min768} {

    }
    
    ${breakpointsMedias.min1200} {
        &.has-color {
            background: none;
        }
       
        position: relative;
        /* height: 80px; */
        padding: 0 60px;
        height: 118px;
        .wrap-header {
            /* padding: 0 28px;
            max-width: 1300px;
            margin: 0 auto; */
            #logo {
                margin-left: -19px;
            }
            .menu {
                flex: 1;
                height: 100%;
            }
        }
        .menu-mobile {
            display: none;
        }
    }
    ${breakpointsMedias.min1600} {
        height: 118px;
        .wrap-header {
            padding: 0 100px;
            max-width: 1440px;
            margin: 0 auto;
            .menu {
                flex: 1;
                height: 100%;
                display: flex;
                justify-content: center;
            }
        }
        .menu-mobile {
            display: none;
        }
    }
`