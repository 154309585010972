/* eslint-disable import/no-anonymous-default-export */
export default {
    colorWhite: "#ffffff",
    colorPrimary: "#FE8C00",
    colorGreen: "#40C72A",
    colorGray: "#E6E6E6",
    colorBorder: "#828282",
    bgPrimary: "#000000",
    lightGray: "#C0C0C0",
    lightWhite: "#E6E6E6",
    colorBG: "#0C0B0D"
}