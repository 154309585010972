import Button from 'components/core/Button'
import ModalWrap from 'components/core/ModalWrap'
import { breakpointsMedias } from 'configs/breakpoints'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import imgBanner2 from "assets/images/banner-img-2.png"

interface IModalTutorial {
    onClose: () => void,
}

const ModalComingSoon = ({ onClose }: IModalTutorial) => {
    // useLockBodyScroll();
    const { t } = useTranslation();

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap className='mini-tutor'>
                <div className="modal-title">
                    <span className='size-3 text-center color-white'>Coming Soon...</span>
                </div>
                <div className={`modal-body text-center`}>
                    <div className="mb-img">
                        <img src={imgBanner2} alt="" />
                    </div>

                </div>
            </Wrap>
        </ModalWrap>
    )
}

export default ModalComingSoon

const Wrap = styled.div`
    padding: 26px 12px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 32px);
    max-width: 472px;
    cursor: default;
    .modal-title{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 36px;
        > span {
            width: 100%;
            /* max-width: 175px; */
            text-align: center;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        .mb-img {
            width: 100%;
            display: flex;
            justify-content: center;
            > img {
                width: 70%;
                height: auto;
            }
        }
        .mb-bt {
            max-width: 200px;
        }
    }
    ${breakpointsMedias.min1200} {
        padding: 20px; 
        max-width: 555px;
        .modal-title{
            margin-bottom: 20px;
            > span {
                max-width: unset;
                width: 80%;
            }
        }
        .modal-body {
            flex-direction: row;
            justify-content: space-evenly;
            .mb-bt {
                max-width: 200px;
            }
        }
    }
`